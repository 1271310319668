
<template>
  <label class="option">
    <span class="option-control">
      <span class="radio">
        <input
          type="radio"
          v-bind:checked="checked === value"
          v-bind:value="value"
          v-on:input="$emit('checked', value)"
        />
        <span />
      </span>
    </span>

    <span class="option-label">
      <span class="option-head">
        <span class="option-title">
          {{ title }}
        </span>

        <span class="option-focus">
          {{ focus }}
          <slot v-if="!focus" name="focus" />
        </span>
      </span>

      <span class="option-body">
        {{ body }}
        <slot v-if="!body" name="body" />
      </span>
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: "checked",
    event: "checked",
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    focus: {
      type: String,
      default: null,
    },

    body: {
      type: String,
      default: null,
    },

    checked: {
      type: Boolean,
      required: true,
    },

    value: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return { };
  },
};
</script>

<style lang="scss" scoped>
.option {
  .option-label {
    .option-body {
      color: $dark-50;
      font-size: 0.95rem;
    }
  }
}
</style>
