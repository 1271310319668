<template>
  <b-card class="card-custom gutter-b" no-body>
    <b-tabs
      card
      pills
      justified
      nav-class="dashboard-tabs nav-danger row row-paddingless m-0 p-0 flex-column flex-sm-row"
    >
      <OrderSettings v-on:mounted="onTabMounted" />
      <CarSettings v-on:mounted="onTabMounted" />
      <DriverSettings v-on:mounted="onTabMounted" />
      <NotificationSettings v-on:mounted="onTabMounted" />
    </b-tabs>
  </b-card>
</template>

<script>
import CarSettings          from "@/view/pages/settings/tabs/CarSettings.vue";
import DriverSettings       from "@/view/pages/settings/tabs/DriverSettings.vue";
import NotificationSettings from "@/view/pages/settings/tabs/NotificationSettings.vue";
import OrderSettings        from "@/view/pages/settings/tabs/OrderSettings.vue";

export default {
  name: "Settings",

  components: {
    CarSettings,
    DriverSettings,
    NotificationSettings,
    OrderSettings,
  },

  data() {
    return {
      breadcrumbs:  [
        { title: "Setări", route: { name: "settings" } }
      ],
    };
  },

  watch: {
    breadcrumbs(breadcrumbs) {
      this.$store.dispatch("setBreadcrumb", breadcrumbs);
    },
  },

  methods: {
    onTabMounted(tab) {
      if (this.breadcrumbs.length >= 2) {
        this.breadcrumbs.pop();
      }

      this.breadcrumbs.push({ title: `Setări ${tab.title}` });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .tabs {
    .card-header {
      .nav {
        .nav-item {
          &:last-child {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}
</style>
