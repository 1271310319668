<template>
  <b-tab
    lazy
    disabled
    title-item-class="d-flex col-sm flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0"
    title-link-class="border py-10 d-flex flex-grow-1 rounded flex-column align-items-center"
    v-bind:active.sync="active"
  >
    <template v-slot:title>
      <span class="nav-icon py-2 w-auto">
        <!-- eslint-disable max-len -->
        <!-- eslint-disable vue/html-self-closing -->
        <!-- eslint-disable vue/max-attributes-per-line -->
        <!--begin::Svg Icon | path:media/svg/icons/Communication/Mail-notification.svg-->
        <span class="svg-icon svg-icon-3x">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z" fill="#000000" />
              <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5" />
            </g>
          </svg>
        </span>
        <!--end::Svg Icon-->
        <!-- eslint-enable max-len -->
        <!-- eslint-enable vue/html-self-closing -->
        <!-- eslint-enable vue/max-attributes-per-line -->
      </span>

      <span class="nav-text font-size-lg py-2 font-weight-bold text-center">
        {{ title }}
      </span>
    </template>

    <b-alert show>{{ title }}</b-alert>
  </b-tab>
</template>

<script>
export default {
  data() {
    return {
      title: "Notificări",
      active: false,
    };
  },

  watch: {
    active(active) {
      if (active) {
        this.$emit("mounted", { title: this.title });
      }
    },
  },
};
</script>