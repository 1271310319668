
<template>
  <b-form-group
    plain
    label-class="h3"
    v-bind:label="label"
  >
    <b-row>
      <b-col cols-lg="6" class="d-flex">
        <Option
          v-model="checked"
          v-bind:value="true"
          v-bind:title="yesTitle"
          v-bind:focus="yesFocus"
          v-bind:body="yesBody"
        >
          <template v-if="!yesFocus" v-slot:focus>
            <slot name="yes-focus" />
          </template>

          <template v-if="!yesBody" v-slot:body>
            <slot name="yes-body" />
          </template>
        </Option>
      </b-col>

      <b-col cols-lg="6" class="d-flex">
        <Option
          v-model="checked"
          v-bind:value="false"
          v-bind:title="noTitle"
          v-bind:focus="noFocus"
          v-bind:body="noBody"
        >
          <template v-if="!noFocus" v-slot:focus>
            <slot name="no-focus" />
          </template>

          <template v-if="!noBody" v-slot:body>
            <slot name="no-body" />
          </template>
        </Option>
      </b-col>
    </b-row>

    <template v-if="!label" v-slot:label>
      <slot name="label" />
    </template>
  </b-form-group>
</template>

<script>
import Option from "./Option.vue";

export default {
  components: {
    Option,
  },

  model: {
    prop: "value",
    event: "change",
  },

  props: {
    label: {
      type: String,
      default: null,
    },

    yesTitle: {
      type: String,
      default: "Da",
    },

    yesFocus: {
      type: String,
      default: null,
    },

    yesBody: {
      type: String,
      default: null,
    },

    noTitle: {
      type: String,
      default: "Nu",
    },

    noFocus: {
      type: String,
      default: null,
    },

    noBody: {
      type: String,
      default: null,
    },

    value: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      checked: this.value,
    };
  },

  watch: {
    value() {
      this.checked = this.value;
    },

    checked() {
      this.$emit("change", this.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
fieldset {
  ::v-deep {
    display: flex;

    > div[role="group"] {
      display: flex;
    }
  }
}
</style>
