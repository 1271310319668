
<template>
  <b-tab
    lazy
    title-item-class="d-flex col-sm flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0"
    title-link-class="border py-10 d-flex flex-grow-1 rounded flex-column align-items-center"
    v-bind:active.sync="active"
  >
    <template v-slot:title>
      <span class="nav-icon py-2 w-auto">
        <!-- eslint-disable max-len -->
        <!-- eslint-disable vue/html-self-closing -->
        <!-- eslint-disable vue/max-attributes-per-line -->
        <!--begin::Svg Icon | path:media/svg/icons/Home/Library.svg-->
        <span class="svg-icon svg-icon-3x">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24"></rect>
              <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000"></path>
              <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1"></rect>
            </g>
          </svg>
        </span>
        <!--end::Svg Icon-->
        <!-- eslint-enable max-len -->
        <!-- eslint-enable vue/html-self-closing -->
        <!-- eslint-enable vue/max-attributes-per-line -->
      </span>

      <span class="nav-text font-size-lg py-2 font-weight-bold text-center">
        {{ title }}
      </span>
    </template>

    <b-row>
      <b-col cols="6" class="d-flex">
        <YesNoOption
          v-if="settings"
          v-model="settings.customer.sendToStations"

          yes-title="Stații și licitație"
          v-bind:yes-focus="`${stationsSeconds} secunde`"

          no-title="Doar licitație"
          v-bind:no-focus="`${biddingSeconds} secunde`"
        >
          <template v-slot:label>
            <h3>Trimite comenzile de la <span class="text-info">client</span> către:</h3>
          </template>

          <template v-slot:yes-body>
            Comenzile se vor trimite la stația de taxi &amp; rezervele ei.
            <br />
            Dacă nu se găsește o mașină in stații, comanda se va trimite spre licitație.
          </template>

          <template v-slot:no-body>
            Comenzile se vor trimite doar spre licitație, în razele configurate.
          </template>
        </YesNoOption>
      </b-col>

      <b-col cols="6" class="d-flex">
        <YesNoOption
          v-if="settings"
          v-model="settings.dispatch.legacyFallback"

          yes-body="Comenzile se vor trimite către tabletă dacă nu se găsește o mașină."
          no-body="Comenzile se vor anula dacă nu se găsește o mașină."
        >
          <template v-slot:label>
            <h3>Trimite comenzile către <span class="text-danger">tableta din dispecerat</span> în ultima instanță:</h3>
          </template>
        </YesNoOption>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="separator separator-dashed my-8" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <YesNoOption
          v-if="settings"
          v-model="settings.dispatch.sendToStations"

          yes-title="Stații și licitație"
          v-bind:yes-focus="`${stationsSeconds} secunde`"

          no-title="Doar licitație"
          v-bind:no-focus="`${biddingSeconds} secunde`"
        >
          <template v-slot:label>
            <h3>Trimite comenzile din <span class="text-warning">dispecerat</span> către:</h3>
          </template>

          <template v-slot:yes-body>
            Comenzile se vor trimite la stația de taxi &amp; rezervele ei.
            <br />
            Dacă nu se găsește o mașină in stații, comanda se va trimite spre licitație.
          </template>

          <template v-slot:no-body>
            Comenzile se vor trimite doar spre licitație, în razele configurate.
          </template>
        </YesNoOption>
      </b-col>

      <b-col cols="6">
        <YesNoOption
          v-if="settings"
          v-model="settings.dispatch.autoConfirm"

          yes-body="Apelurile se vor trimite către Robo pentru confirmare automată."
          no-body="Apelurile vor rămâne la dispecer pentru confirmare manuală."
        >
          <template v-slot:label>
            <h3>Trimite apelurile către <span class="text-primary">Robo</span> pentru confirmare automată:</h3>
          </template>
        </YesNoOption>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import lodash from "lodash";
import moment from "moment";
import * as jsonpatch from "fast-json-patch";

import api from "@/core/services/api";
import notify from "@/core/services/notifications";

import YesNoOption from "@/view/content/widgets/form/YesNoOptions.vue";

export default {
  components: {
    YesNoOption,
  },

  data() {
    return {
      title: "Comenzi",
      active: false,

      settings: null,
      original: null,
    };
  },

  computed: {
    biddingSeconds() {
      if (!this.settings) return null;

      const delayBetween = moment.duration(this.settings.bidding.delayBetweenCalls).asSeconds();
      const totalDelay   = delayBetween * (this.settings.bidding.ranges.length - 1);

      const ranges = this.settings.bidding.ranges.map(r => moment.duration(r.duration).asSeconds());

      const rangeDuration = lodash.sum(ranges);
      const totalDuration = (totalDelay + rangeDuration) * this.settings.bidding.repeatTimes;

      return totalDuration;
    },

    stationsSeconds() {
      if (!this.settings) return null;

      const stationsDuration = moment.duration(this.settings.stations.duration).asSeconds();
      const totalDuration    = stationsDuration + this.biddingSeconds;

      return totalDuration;
    },
  },

  watch: {
    async active(active) {
      if (active) {
        this.$emit("mounted", { title: this.title });

        await this.loadSettings();
      }
    },

    async "settings.customer.sendToStations"(_, oldVal) {
      if (typeof oldVal === "undefined") return;

      await this.updateSettings();
    },

    async "settings.dispatch.sendToStations"(_, oldVal) {
      if (typeof oldVal === "undefined") return;

      await this.updateSettings();
    },

    async "settings.dispatch.legacyFallback"(_, oldVal) {
      if (typeof oldVal === "undefined") return;

      await this.updateSettings();
    },

    async "settings.dispatch.autoConfirm"(_, oldVal) {
      if (typeof oldVal === "undefined") return;

      await this.updateSettings();
    },
  },

  methods: {
    async loadSettings() {
      const settings = await api.settings.getOrder();

      this.original = lodash.cloneDeep(settings);
      this.settings = settings;
    },

    async updateSettings() {
      const patch   = jsonpatch.compare(this.original, this.settings);
      const updated = await api.settings.patchOrder(patch);

      this.original = lodash.cloneDeep(updated);
      this.settings = updated;

      notify.success("Setările au fost actualizate cu succes.");
    },
  },
};
</script>
